<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h2 class="header-title">Audit Trail</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<button class="btn btn-light mb-3 btn-sm" @click="$router.go(-1)">
				Go back
			</button>
		</div>
	</div>
</template>

<script>
export default {
  name: 'AuditTrailList'
}
</script>

<style scoped>
</style>
